// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// import OutLink from '@bodhi-project/components/lib/OutLink'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Nav from './Nav'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Desktop
const desktopNavStyle = css({
  padding: '0px 26px',
  width: '100%',
  transition: 'background 0.6s cubic-bezier(0.78, 0.14, 0.15, 0.86)',

  '& .ant-menu': {
    padding: 0,
    background: 'transparent',
    border: 0,

    '& span': {
      fontSize: '80%',
    },

    '& .ant-menu-submenu-arrow': {
      display: 'none',
    },

    '& .ant-menu-submenu > .ant-menu': {
      background: 'transparent',
    },

    '& .ant-menu-submenu': {
      marginBottom: '0px !important',
    },

    '& .ant-menu-sub.ant-menu-inline': {
      marginBottom: 20,
    },

    '& .ant-menu-sub.ant-menu-inline > .ant-menu-item': {
      lineHeight: '22px',
      height: 22,
    },

    '& .ant-menu-item > a': {
      lineHeight: '22px',
    },

    '& .ant-menu-item, .ant-menu-submenu-title': {
      padding: '0px !important',
      marginBottom: '0px !important',
      background: 'transparent',
      fontFamily: `"myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important`,
    },

    '& .ant-menu-submenu-title': {
      lineHeight: '26px !important',
      height: '26px !important',

      '& p': {
        fontWeight: 700,
        color: '#004195',
        lineHeight: '26px',
      },
    },
  },
})
const desktopNavStyleClass = desktopNavStyle.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** DesktopNav */
const DesktopNav = props => {
  return (
    <div id="desktop-nav" className={desktopNavStyleClass}>
      <Nav mode="inline" />
    </div>
  )
}

// --------------------------------------------------------------------- Export
export default DesktopNav
