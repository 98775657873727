// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Lodash
// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";
// import startsWith from "lodash/startsWith";
// import split from "lodash/split";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Drawer from 'antd/lib/drawer'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/drawer/style/css'

import Button from 'antd/lib/button'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/button/style/css'

import Icon from 'antd/lib/icon'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/icon/style/css'

// import OutLink from "@bodhi-project/components/lib/OutLink";
import Image from '@bodhi-project/components/lib/Image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Nav from './Nav'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const className = css({
  '& .ant-btn.ant-btn-primary.ant-btn-lg': {
    marginTop: 10,
    paddingTop: 13.25,
    paddingBottom: 13.25,
    paddingLeft: 16,
    paddingRight: 16,
    height: 60,
    fontSize: 30,
  },

  '& .ant-drawer-header': {
    borderBottom: '2px solid #004195',
  },
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** MobileNav */
class MobileNav extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.showDrawer = this.showDrawer.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  /** onClose */
  onClose() {
    this.setState({
      visible: false,
    })
  }

  /** showDrawer */
  showDrawer() {
    this.setState({
      visible: true,
    })
  }

  /** standard renderer */
  render() {
    const { websiteMenu, Link, title, logo } = this.props

    return (
      <div id="mobile-nav" className={className}>
        <div
          style={{
            display: 'flex',
            padding: 2,
            justifyContent: 'space-between',
          }}
        >
          <Link
            to="/"
            style={{
              display: 'block',
              border: 'unset',
            }}
          >
            <Image
              src={logo}
              rawWidth={2000}
              rawHeight={500}
              style={{
                display: 'block',
                height: 80,
                width: 'auto',
                border: 0,
                background: 'transparent',
              }}
              loader="gradient"
              alt="NVC India"
            />
          </Link>
          <div style={{ paddingTop: 0 }}>
            <Button type="primary" onClick={this.showDrawer} size="large">
              <Icon
                type="plus-circle"
                theme="outlined"
                style={{ height: 30, width: 30, verticalAlign: 0 }}
              />
            </Button>
          </div>
        </div>
        <Drawer
          title={title}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          style={{
            padding: 0,
          }}
          width="45vw"
          className={className}
        >
          <div style={{ minHeight: 'calc(100vh - 56px)' }}>
            <Nav websiteMenu={websiteMenu} Link={Link} mode="inline" />
          </div>
        </Drawer>
      </div>
    )
  }
}

MobileNav.propTypes = {
  location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  menu: PropTypes.array, // eslint-disable-line react/forbid-prop-types
}

// --------------------------------------------------------------------- Export
export default MobileNav
