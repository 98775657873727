// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
// import PropTypes from "prop-types";
import { css } from 'glamor'

import { Link } from 'gatsby'

import logo from '../../assets/outline2.png'

// import isNull from "lodash/isNull";

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Page style
const styleObject = css({
  padding: '16px 24px 0px',

  '& a': {
    display: 'block',
    position: 'relative',

    '& h1': {
      marginBottom: 0,
      textIndent: '-9999em',
    },

    '& h2': {
      transform: 'rotate(-90deg)',
      position: 'absolute',
      margin: 0,
      color: '#004195',

      '& span': {
        color: '#FFC900',
      },
    },

    '&:hover': {
      borderBottom: 'unset',
    },
  },
})

const style = styleObject.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * WebsiteTitle
 * @param  {[type]} options.parentWidth [description]
 * @return {[type]}                     [description]
 */
const WebsiteTitle = ({ parentWidth }) => {
  const height = parentWidth * 0.625
  const imageHeight = height - 10
  // const imageWidth = imageHeight * 1.222

  return (
    <header className={style}>
      <Link
        to="/"
        style={{
          height,
          width: '100%',
        }}
      >
        <h1
          style={{
            height: imageHeight,
            width: imageHeight,
            background: `url(${logo}) 0% 0% / ${imageHeight}px ${imageHeight}px no-repeat`,
          }}
        >
          Cambridge Innovative School
        </h1>
      </Link>
    </header>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default WebsiteTitle
