// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  display: 'flex',

  '@media(max-width: 992px)': {
    flexWrap: 'wrap',
  },

  '& a': {
    display: 'block',
    '@media(min-width: 992px)': {
      flex: '1 0 0',
    },

    '@media(max-width: 992px)': {
      flex: '1 0 30%',
    },

    '& small': {
      display: 'block',
      width: '100%',
      textAlign: 'center',
    },

    ':not(:first-child)': {
      marginLeft: 5,
    },
  },
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Schools */
const Schools = ({ flexDirection = 'row' }) => {
  return (
    <Fragment>
      <h2 className="mask-h6">Our Cambridge Community…</h2>
      <p>
        Cambridge Innovative School is a part of the Learning Wings family.
        Learning Wings is a global organisation devoted to high-quality
        education that meets the demands of parents, students and education
        providers. It is also a leading provider of school management services
        and works closely with schools to raise student achievements. Cambridge
        International School are showing children the process of realizing their
        potential. Our family:
      </p>
      <StaticQuery
        query={graphql`
          query {
            
            jalandharFoundation: file(
              relativePath: { eq: "footer/jalandhar-foundation.jpg" }
            ) {
              ...defaultImage
            }
            jalandharGirls: file(
              relativePath: { eq: "footer/jalandhar-girls.jpg" }
            ) {
              ...defaultImage
            }
            
            mohali: file(relativePath: { eq: "footer/mohali.jpg" }) {
              ...defaultImage
            }
            nakodar1: file(relativePath: { eq: "footer/nakodar1.jpg" }) {
              ...defaultImage
            }
          }
        `}
        render={data => (
          <div className={`${style} mask-p`} style={{ flexDirection }}>
          
              
           
            <Link to="https://www.cifsjalandhar.com/">
              <Img
                fluid={data.jalandharFoundation.childImageSharp.fluid}
                title="Cambridge International Foundation School, Jalandhar"
                alt="Cambridge International Foundation School, Jalandhar"
              />
              <small>CIS Foundation, Jalandhar</small>
            </Link>
            <Link to="https://www.cisfgjal.school/">
              <Img
                fluid={data.jalandharGirls.childImageSharp.fluid}
                title="Cambridge Innovative School, Jalandhar"
                alt="Cambridge Innovative School, Jalandhar"
              />
              <small>CIS, Jalandhar</small>
            </Link>
            
            <Link to="http://www.cambridgemohali.com/">
              <Img
                fluid={data.mohali.childImageSharp.fluid}
                title="Cambridge International School, Mohali"
                alt="Cambridge International School, Mohali"
              />
              <small>CIS, Mohali</small>
            </Link>
            
          </div>
        )}
      />
    </Fragment>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Schools
