// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
// import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

import { FacebookProvider, Page as FBPage } from 'react-facebook'
import { Timeline } from 'react-twitter-widgets'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// const style = css({}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Schools */
const Schools = () => {
  return (
    <Division golden>
      <div>
        <h2 className="mask-h6">Find Us On Twitter</h2>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'cambridge_jal',
          }}
          options={{
            username: 'cambridge_jal',
            height: '1000',
          }}
        />
        <br />
        <br />
      </div>
      <div>
        <h2 className="mask-h6">Find Us On Facebook</h2>
        <div style={{ maxHeight: 1000, overflow: 'hidden' }}>
          <FacebookProvider appId="218604115574634">
            <FBPage
              href="https://www.facebook.com/Cambridge-International-School-for-Girls-Jalandhar-697949783958565"
              tabs="timeline,events,messages"
            />
          </FacebookProvider>
        </div>
        <br />
        <br />
      </div>
    </Division>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Schools
