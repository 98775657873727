// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
// import classNames from "classnames";

// import map from 'lodash/map'
// import isUndefined from 'lodash/isUndefined'
// import startsWith from 'lodash/startsWith'
// import isArray from "lodash/isArray";
// import isString from "lodash/isString";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Footer as SemanticFooter } from '@bodhi-project/semantic-webflow'

import Image from '@bodhi-project/components/lib/Image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import wavesBottom from '../../assets/wavesBottom.png'
import girlBoat from '../../assets/girlBoat.png'

import Schools from '../commons/Schools'
import SocialMedia from '../commons/SocialMedia'
import Copyright from '../commons/Copyright'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Footer
const footerStyle = css({
  position: 'relative',

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '@media(min-width: 992px)': {
    '& .wave': {
      marginRight: '-3em',
      marginLeft: '-3em',
    },
  },
})

const footerStyles = footerStyle.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
const Footer = props => {
  return (
    <div className={footerStyles}>
      <div
        style={{
          position: 'relative',
          height: 30,
          marginBottom: 0,
          marginTop: 36,
        }}
        className="wave"
      >
        <Image
          src={wavesBottom}
          style={{
            height: 30,
            width: '100%',
            border: 0,
            background: 'transparent',
            top: -12,
          }}
        />
        <div style={{ position: 'absolute', right: 0, top: -102 }}>
          <Image
            src={girlBoat}
            style={{
              height: 120,
              width: 120,
              border: 0,
              background: 'transparent',
            }}
          />
        </div>
      </div>
      <SemanticFooter>
        <header>
          <h1 className="mask-h4">Connect With Us</h1>
          <p>
            As a leading school our mission is to nurture the development of
            responsible thoughtful citizens, for life in an increasingly
            interdependent global society by creating environment in which
            students are challenged to explore, to create and to make decisions.
          </p>
        </header>
        <SocialMedia />
        <Schools />
        <Copyright />
      </SemanticFooter>
    </div>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Footer
