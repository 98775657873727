// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import moment from 'moment'
import { graphql } from 'gatsby'

import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Type } from '@bodhi-project/typography'
import {
  InitializeMeta,
  UpdateTitle,
  WebsiteSchema,
  OrganisationSchema,
} from '@bodhi-project/seo'

import ContainerDimensions from 'react-container-dimensions'

import Container from '@bodhi-project/components/lib/Container'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import '../../styles/index.less'
import indexImage from '../../assets/ogX.jpg'
import cisGirlsMobileLogo from '../../assets/cisGirlsMobileLogo.jpg'
import cisGirlsMobileLogo1 from '../../assets/cisGirlsMobileLogo1.jpg'
import data from '../../data/website.json'
import websiteMenu from '../../data/menu.json'

import Link from '../Link'

import WebsiteTitle from './WebsiteTitle'
import DesktopFooter from './DesktopFooter'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
export const defaultImage = graphql`
  fragment defaultImage on File {
    childImageSharp {
      fluid(
        maxWidth: 2400
        quality: 80
        srcSetBreakpoints: [200, 400, 600, 800, 1000, 1200, 1600, 2000, 2400]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------- Global SEO
// ----------------------------------------------------------------------------
const websiteSchemaData = {
  url: data.websiteUrl,
  name: data.websiteName,
  description: data.websiteDescription,
  author: data.org.name,
  publisher: data.org.name,
  image: indexImage,
}

const organisationSchemaData = {
  name: data.org.name,
  legalName: data.org.legalName,
  url: data.org.url,
  logo: `${data.org.url}${data.org.logo}`,
  foundingDate: moment(data.org.foundingDate).format(),
  founders: data.org.founders,
  streetAddress: data.orgLocation.streetAddress,
  addressLocality: data.orgLocation.addressLocality,
  addressRegion: data.orgLocation.addressRegion,
  postalCode: data.orgLocation.postalCode,
  addressCountry: data.orgLocation.addressCountry,
  telephone: data.orgContact.telephone,
  email: data.orgContact.email,
  sameAs: data.orgSocialMediaProfiles,
  image: indexImage,
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Page style
const pageStyle = css({
  // background: "#0053a5",
  padding: 0,

  '& #side-layout': {
    display: 'block',
    backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #fff3c4 100%)',

    '& #contentWrapper': {
      backgroundColor: '#ffffff',
      background: '#ffffff',
      minHeight: '100vh',
    },

    '@media(min-width: 992px)': {
      display: 'flex',

      '& #menuWrapper': {
        flexGrow: '19',
        flexBasis: 0,
        height: '100vh',
        overflowY: 'scroll',
      },

      '& #contentWrapper': {
        marginTop: '1.6vh',
        boxShadow: '0 0 25px rgba(0,0,0,.11)',
        flexGrow: '81',
        flexBasis: 0,
        maxHeight: '100vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        borderTopLeftRadius: 32,

        '& #content': {
          paddingTop: '2em',
          paddingRight: '3em',
          paddingBottom: '6em',
          paddingLeft: '3em',
          marginLeft: 0,
        },

        '& #footer': {
          paddingRight: '3em',
          paddingLeft: '3em',
          paddingBottom: '6em',
          marginLeft: 0,
        },
      },
    },
  },

  '& #footer': {
    marginTop: 120,
  },

  '& #menuWrapper': {
    zIndex: 1000,
  },

  '& #content': {
    marginBottom: '4em',
  },

  '@media(min-width: 992px)': {
    '& .mobile-only': {
      display: 'none',
    },
  },

  '@media(max-width: 992px)': {
    '& #contentWrapper': {
      padding: 16,
    },

    '& .desktop-only': {
      display: 'none',
    },
  },
})
const pageStyles = pageStyle.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)
  }

  /** after mount */
  componentDidMount() {
    if (!isUndefined(document)) {
      const htmlElement = document.documentElement
      if (htmlElement.classList.contains('lk-loading')) {
        htmlElement.classList.toggle('lk-loading')
      }
      if (!htmlElement.classList.contains('lk-active')) {
        htmlElement.classList.toggle('lk-active')
      }
    }
  }

  /** on mount */
  componentDidUpdate() {
    if (!isUndefined(window)) {
      const element = document.getElementById('contentWrapper')
      element.scrollTop = 0
    }
  }

  /** standard renderer */
  render() {
    const { children } = this.props

    return (
      <Type
        kit="ftw5ybq"
        style={{ minHeight: '100vh' }}
        className={pageStyles}
        options={{
          range: [14, 21], // Min and Max font-sizes
          paragraphSpacingFactor: 1.2, // Greater for tighter paragraph-paragraph spacing
          headingParagraphGapSpacingFactor: 0, // Greater for tighter header-paragraph spacing
          indentParagraphs: false,
        }}
      >
        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SEO */}
        <InitializeMeta
          data={{
            titleTemplate: '%s | Cambridge Innovative School',
          }}
        />
        <UpdateTitle title="Home" />
        <WebsiteSchema data={websiteSchemaData} />
        <OrganisationSchema data={organisationSchemaData} />
        <div id="side-layout">
          <header className="desktop-only" id="menuWrapper">
            <div>
              <ContainerDimensions>
                {({ width }) => {
                  const parentWidth = width
                  return <WebsiteTitle parentWidth={parentWidth} />
                }}
              </ContainerDimensions>
              <DesktopNav menu={websiteMenu} {...this.props} />
            </div>
          </header>
          <aside
            className="mobile-only"
            style={{
              background: '#FFF',
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <MobileNav
              websiteMenu={websiteMenu}
              Link={Link}
              title="Cambridge Innovative School, Jalandhar"
              logo={cisGirlsMobileLogo1}
            />
          </aside>

          <div id="contentWrapper">
            <Container big block style={{ marginLeft: 0 }}>
              <div id="content">{children}</div>
              <div id="footer" style={{ background: '#fafafa' }}>
                <DesktopFooter />
              </div>
            </Container>
          </div>
        </div>
      </Type>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------- Export
export default Layout
